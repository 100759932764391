<template>
  <div class="wameed-dashboard-page-content mb-5">
    <div>
      <page-header
        :title="$t('settings.fields')"
        :sub-title="$t('settings.fields_subtitle')"
      />
    </div>
    <section class="wameed-dashboard-page-content_body">
      <div class="mx-4">
        <b-row class="">
          <b-col
            md="6"
            class="my-4"
          >
            <div
              class="package-details-table bg-white overflow-hidden rounded-14"
            >
              <b-row class="">
                <b-col
                  sm="12"
                  class="package-details-name"
                >
                  <h1
                    class="
                      package-details-table__title
                      text-font-main text-bold-16
                    "
                  >
                    {{ $t('common.edit_field_data') }}
                  </h1>
                </b-col>
              </b-row>
              <b-row class="package-details-table__body">
                <b-col cols="12">
                  <label class="text-bold-14 text-font-main required">{{
                    $t('common.field_name')
                  }}</label>
                </b-col>
                <b-col md="6">
                  <div class="p-0">
                    <text-input
                      id="name_ar"
                      v-model="submitData.name_ar"
                      :placeholder="$t('common.enter_ar_name')"
                      input-classes="text-bold-14 text-font-main"
                      name="name_ar"
                      is-append
                      :append-text="'ع'"
                    />
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="p-0">
                    <text-input
                      id="name_en"
                      v-model="submitData.name_en"
                      :placeholder="$t('common.enter_en_name')"
                      input-classes="text-bold-14 text-font-main"
                      name="name_en"
                      is-append
                      :append-text="'E'"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row class="package-details-table__body">
                <wameed-btn
                  :classes="'rounded-12 text-bold-14 text-white ml-3'"
                  :title="$t('common.save_edited')"
                  type="button"
                  variant="main"
                  @submitAction="editFieldFun()"
                />
                <wameed-btn
                  :classes="'rounded-12 text-bold-14 text-white ml-3'"
                  :title="$t('common.delete')"
                  type="button"
                  variant="danger"
                  @submitAction="deleteFieldModal = true"
                />
                <wameed-btn
                  classes="ml-auto mr-3 text-med-14 text-font-secondary rounded-10 "
                  :title="$t('common.cancel')"
                  type="button"
                  variant="disable"
                  onclick="history.back()"
                />
              </b-row>
              <warning-modal
                variant="danger"
                variant2="danger"
                icon-name="stopping-icon"
                :visible="deleteFieldModal"
                :title="$t('common.confirm_field_deletion_title')"
                :sub-title="$t('common.confirm_field_deletion_subtitle')"
                :btn-title="$t('common.delete')"
                @close="deleteFieldModal = false"
                @submitAction="deleteFieldFun()"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/PageHeader.vue';
import WarningModal from '@/components/WarningModal.vue';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextInput from '@/components/TextInput.vue';

export default {
  components: {
    PageHeader,
    WarningModal,
    ValidationObserver,
    WameedForm,
    WameedBtn,
    TextInput,
  },
  data() {
    return {
      deleteFieldModal: false,
      submitData: {
        name_ar: '',
        name_en: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      getSectorDetails: 'getSectorDetails',
    }),
  },
  watch: {
    getSectorDetails: {
      handler() {
        this.submitData = this.getSectorDetails;
      },
      deep: true,
    },
  },
  mounted() {
    this.loadSectorsDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadSectorsDetails: 'loadSectorsDetails',
      editSector: 'editSector',
      deleteSector: 'deleteSector',
    }),
    editFieldFun() {
      this.editSector(
        {
          id: this.$router.currentRoute.params.id,
          name_ar: this.submitData.name_ar,
          name_en: this.submitData.name_en,
        },
      ).then(result => {
        this.$router.push({
          name: 'fields',
          params: { lang: this.$i18n.locale },
        });
      });
    },
    deleteFieldFun() {
      this.deleteSector(this.$router.currentRoute.params.id);
      this.$router.push({
        name: 'fields',
        params: { lang: this.$i18n.locale },
      });
    },
  },
};
</script>
